<template>
  <CRow>
        <CToaster :autohide="3000">
      <template v-for="(toast, index) in toasts">
        <CToast :key="'toast' + index" :show="true" :header="toast.title">
          {{ toast.message }}
        </CToast>
      </template>
    </CToaster>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol col="12" xl="12">
            <CCard>
              <CCardHeader style="display: flex; justify-content: space-between">
                <div>

                Usuarios (Usuarios Aplicación)
                <CButton
                  color="primary"
                  size="sm"
                  class="m-1"
                  @click="updateUser(false, $event)"
                >
                  Agregar
                </CButton>
                </div>
                
                
                <CButton
                  color="success"
                  size="sm"
                  class="m-1"
                  @click="ExportExcelUsers"
                >
                <CSpinner
                v-if="loaderExcel"
                style="width:1rem;height:1rem;"
                color="warning"
              />
              <p v-else style="margin-bottom: 0;">
              Descargar XLSX
              </p>
                </CButton>
              </CCardHeader>
              <CCardBody>
                <CDataTable
                  v-show="!loading"
                  hover
                  striped
                  :items="items"
                  :fields="fields"
                  :pagination="pagination"
                  index-column
                  clickable-rows
                  :items-per-page="pagination.perPage"
                >
                  <template #telefono="data">
                    <td>{{data.item.info.telefono}}</td>
                  </template>
                  <template #direccion="data">
                    <td>{{data.item.info.direccion}}</td>
                  </template>
                  <template #sponsor="data">
                    <td>
                      <CButton
                        v-c-tooltip="data?.item?.sponsor ? 'Editar patrocinador asociado' : 'Asociar patrocinador'"
                        color="warning"
                        size="sm"
                        class="m-1"
                        @click="modalUpdateSponsor(data, $event)"
                      >
                        <font-awesome-icon v-show="data?.item?.sponsor" icon="edit" />
                        <font-awesome-icon v-show="!data?.item?.sponsor" icon="plus" />
                      </CButton>
                      {{data?.item?.sponsor?.name}}</td>
                  </template>
                  <template #cuadrante="data">
                    <td>{{getCuadranteName(data.item.info.cuadrante_id)}}</td>
                  </template>
                  <template #actions="data">
                    <div>
                      <CButton
                        v-c-tooltip="'Editar datos de usuario'"
                        color="warning"
                        size="sm"
                        class="m-1"
                        @click="updateUser(data.item, $event)"
                        v-if="checkPermissions(['edit_user_app'])"
                      >
                        <font-awesome-icon icon="user-edit" />
                      </CButton>
                      <CButton
                        v-c-tooltip="'Eliminar usuario'"
                        color="danger"
                        size="sm"
                        class="m-1"
                        @click="deleteUser(data.item.id, $event)"
                        v-if="checkPermissions(['edit_user_app'])"
                      >
                        <font-awesome-icon icon="trash-alt" />
                      </CButton>
                    </div>
                  </template>
                </CDataTable>
                <div 
                  v-if="loading"
                  class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
                  <CSpinner
                    style="width:4rem;height:4rem;"
                    color="danger"
                  />
                  <p class="text-secondary my-2">Cargando datos ...</p>
                </div>
            <Pagination :data="pagination" :callback="paginate"/>
              </CCardBody>
            </CCard>
          </CCol>
<!--           <CCol col="3" xl="3">
            <CCard>
              <CCardHeader>
                Estadísticas
              </CCardHeader>
              <CCardBody>
                <CChartPie
                  v-if="estadisticas && estadisticas.gender && estadisticas.gender.values.length > 0"
                  :datasets="[{
                    backgroundColor: [
                      '#41B883',
                      '#d0282e',
                      '#00D8FF',
                      '#DD1B16'
                    ],
                    data: estadisticas.gender.values
                  }]"
                  :labels="estadisticas.gender.keys"
                />
                <CChartLine
                  class="mt-5"
                  v-if="estadisticas && estadisticas.age && estadisticas.age.values.length > 0"
                  :datasets="[{
                    label: 'Edad',
                    backgroundColor: ['#d0282e'],
                    data: estadisticas.age.values
                  }]"
                  :labels="estadisticas.age.keys"
                  :options="{ 
                    scales: {
                      yAxes: [{
                        ticks: {
                          stepSize: 1
                        }
                      }]
                    }
                  }"
                />
              </CCardBody>
            </CCard>
          </CCol> -->
        </CRow>
      </transition>
    </CCol>
    <EditUser :showModal="showModal" :user="updatingUser" :cuadrantes="cuadrantes" :closeModal="() => closeModal()"/>

          <!--     Modal Sponsor -->
    <CModal
      style="max-width: 500px !important"
      centered
      title="Editar sponsor"
      color="primary"
      :show.sync="modalEditSponsor"
      size="sm"
      class="modal-delete"
    >
      <CCard>
        <CCardBody style="padding: 0">
          <CRow style="padding: 20px">
            <CCol col="12">
              <CSelect
                v-model="editSponsorId"
                label="Selecciona un Patrocinador"
                :options="sponsors"
              />
              <CButton color="primary" @click="updateSponsor"> Guardar </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <div slot="footer" class="w-100"></div>
    </CModal>
  </CRow>
</template>

<script>
import EditUser from './EditUser'
import { getUsers, deleteUser } from '../../api/users'
import { getCuadrantes } from '../../api/cuadrantes'
import checkPermissions from '../../helpers/userPermissions'
import { CChartPie, CChartLine } from '@coreui/vue-chartjs'
import { excelUsers } from '../../api/users.js'
import { mapState } from 'vuex'
import * as infermedica from '../../api/infermedica'
import Pagination from '../../helpers/Pagination.vue'
import { getAdminSponsors, setUserSponsor} from "../../api/sponsor";


export default {
  name: 'Users',
  components: {EditUser, CChartPie, CChartLine, Pagination},
  data: () => {
    return {
      loaderExcel: false,
      items: [],
      estadisticas: null,
      fields: [
        { key: 'id', label: '#' },
        { key: 'name', label: 'Nombre' },
        { key: 'email' },
        { key: 'telefono', label: 'Teléfono' },
        { key: 'sponsor', label: 'Patrocinador' },
        { key: 'direccion', label: 'Dirección' },
        { key: 'cuadrante', label: 'Cuadrante' },
        { key: 'created_at', label: 'Creado' },
        { key: 'actions', label: 'Acciones' }
      ],
      pagination: {
        page: 1,
        perPage: 10,
        total: null
      },
      updatingUser: null,
      showModal: false,
      loading: true,
      cuadrantes: null,
      modalEditSponsor: false,
      sponsors: [],
      currentUser: {},
      editSponsorId: null,
      toasts: [],
    }
  },
    computed: mapState([
  'userData'
])
,
  mounted(){
    this.getUsers()
    this.getCuadrantes()
    this.getSponsors();

  },
  methods: {
        paginate(page, perPage){
      console.log(page)
      console.log(perPage)
      this.pagination = {
        page: page,
        perPage: perPage,
        total: this.pagination.total
      }
      this.getUsers()
    },
    checkPermissions,
    goToFicha(userID){      
      this.$router.push({ name: 'FichaPaciente', params: {id: userID} })
    },
    getCuadranteName(id){
      if(this.cuadrantes){
        const found = this.cuadrantes.find(function(item){
          return id === item.id
        })
        return found.nombre
      }
    },
    closeModal(){
      this.showModal = false
      this.getUsers()
    },
    updateUser(user, e){
      e.stopPropagation()
      this.updatingUser = user
      this.showModal = true
    },
    deleteUser(id, e){
      e.stopPropagation()
      const data = {id}
      const self = this
      deleteUser(data)
        .then(function(resp){
          console.log(resp)
          self.getUsers()
        })
        .catch(function(error){
          console.log(error)
        })
    },
    getCuadrantes(){
      const data = {
        page: 1,
        limit: 9999999
      }
      const self = this
      getCuadrantes(data)
        .then(function(resp){
          self.cuadrantes = resp.data
        })
        .catch(function(error){
          console.log(error)
        })
    },
    getUsers(){
      const data = {
        limit: this.pagination.perPage,
        page: this.pagination.page
      }
      const self = this
      this.loading = true
      getUsers(data)
        .then(function(resp){
          self.items = resp.data
          console.log(resp)
          self.estadisticas = resp.user_stadistics
          // resp.data.map(function(item){
            //   self.items.push(item)
          // })
          self.pagination = {
            page: self.pagination.page,
            perPage: self.pagination.perPage,
            total: resp.total
          }

            self.loading = false
        })
        .catch(function(error){
          console.log(error)
        })
    },
    getSponsors() {
      getAdminSponsors().then((res) => {
        this.sponsors = res.sponsors.map((sponsor) => {
          return { value: sponsor.id, label: sponsor.name };
        });
        this.sponsors = [...this.sponsors, {value: null, label: ''}]
      });
    },
    modalUpdateSponsor(sponsor) {
      this.modalEditSponsor = true;
      this.currentUser = sponsor.item;
      this.editSponsorId = sponsor?.item?.sponsor?.id ? sponsor?.item?.sponsor?.id : null
    },
      updateSponsor() {
      const data = {
        sponsor_id: this.editSponsorId,
        user_id: this.currentUser.id,
      };

      setUserSponsor(data)
        .then((res) => {
          this.modalEditSponsor = false;
          this.toasts.push({
            title: "Sponsor Actualizado",
          });
          this.getUsers();
        })
        .catch((e) => {
          console.log(e);
          this.toasts.push({
            title: "Error",
          });
        });
    },
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
        ExportExcelUsers() {
          this.loaderExcel = true
      const data = {cuadrante_id: this.userData.info.cuadrante.id, max_limits:50000}
      excelUsers(data).then(res => {
        res.url.forEach(i => {
          window.open(i, '_blank')
          this.loaderExcel = false
        })
          }).catch(e => {
        this.loaderExcel = false
    })
  }
}}
</script>
