import axios from 'axios'
//import { MessageBox } from 'element-ui'
//import store from '@/store'
//import { getToken } from '@/utils/auth'

// create an axios instance

//url produccion = 'http://190.98.210.42/apisos/public/api/'
//url desarrollo = 'http://localhost:8081/api/'

const service = axios.create({
  baseURL: 'https://api.infermedica.com/v2',
  timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers['content-type'] = 'application/json'
    config.headers['App-Id'] = '37926a35'
    config.headers['App-Key'] = '09dc07d38afde267bc9f0912e717d0bf'
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (response.status !== 200) {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (response.status === 403 || response.status === 50008 || response.status === 50012 || response.status === 50014) {
        // to re-login
        localStorage.removeItem('token')
        location.reload()
      }
      return Promise.reject(res || 'Error')
    } else {
      return res
    }
  },
  async error => {
    //console.log(error)
    if(error.response && (error.response.status === 403 || error.response.status === 401)){
      await localStorage.removeItem('token')
      location.reload()
    }
    return Promise.reject(error)
  }
)

export default service