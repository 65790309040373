<template>
  <div>
    <CToaster :autohide="3000">
      <template v-for="(toast, index) in toasts">
        <CToast
          :key="'toast' + index"
          :show="true"
          :header="toast.title"
        >
          {{toast.message}}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Editar Datos de Usuario"
      size="lg"
      color="primary"
      class="edit-user-modal"
      :show.sync="showModal"
    >
      <CCard v-if="userData"
        style="max-height: 70vh; overflow-y: scroll;">
        <CCardHeader>
          <b>{{user.name}}</b>
        </CCardHeader>
        <CCardBody>
          <CRow class="d-flex justify-content-center align-items-center">
            <CCol lg="3" class="c-bg-success c-py-3">
              <img
                v-if="userData.info.avatar"
                style="width: 100%; height: auto;"
                :src="userData.info.avatar"
                class="c-mb-2"
              />
              <div class="icon-user" v-else><font-awesome-icon icon="user"/></div>
            </CCol>
            <CCol lg="9" class="c-bg-success c-py-3">
              <CForm>
                <CInput
                  placeholder="Nombre"
                  v-model='userUpdate.name'
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                  placeholder="Rut"
                  v-model='userUpdate.rut'
                  @input="changedRut()"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                  :class="showAlertEmail ? 'mb-0' : null"
                  placeholder="Email"
                  v-model='userUpdate.email'
                  @input="changedEmail()"
                >
                  <template #prepend-content><CIcon name="cil-envelope-closed"/></template>
                </CInput>
                <div v-show="showAlertEmail" class="text-right mb-1 text-primary" style="font-size: 10px;">
                  Ten en cuenta que al modificar el email, el usuario deberá ingresar con el email que ingreses.
                </div>
                <CInput
                  placeholder="Dirección"
                  v-model='userUpdate.direccion'
                >
                  <template #prepend-content><font-awesome-icon icon="map-marked-alt" /></template>
                </CInput>
                <CInput
                  placeholder="Teléfono"
                  v-model='userUpdate.telefono'
                >
                  <template #prepend-content><font-awesome-icon icon="phone" /></template>
                </CInput>
                <CInput
                  placeholder="Contraseña"
                  :required="!user"
                  v-model='userUpdate.password'
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
                <p class="mb-0">Seleccione Cuadrante</p>
                <v-select :options="cuadrantes" label="nombre" :reduce="data => data.id" v-model="userUpdate.cuadrante_id"></v-select>
                <p class="mb-0">Cluster de Usuario</p>
                <v-select multiple :options="clusters" label="name" :reduce="data => data.id" v-model="userUpdate.cluster"></v-select>
              </CForm>
            </CCol>
          </CRow>
          <CRow class="d-flex justify-content-center align-items-center">
            <CCol lg="12" class="c-bg-success c-py-3">
              <div class="d-flex justify-content-center align-items-center font-weight-bold text-center my-3 py-2 bg-dark text-white">
                <p class="my-0">Prioridades</p>
                <CButton
                  color="secondary"
                  size="sm"
                  class="m-1"
                  @click="addPrioridadesArray()"
                >
                  <font-awesome-icon icon="plus" />
                </CButton>
              </div>
              <div class="d-flex border-bottom py-3 justify-content-center align-items-center w-100" v-for="(prioridad, index) in userUpdate.prioridades">
                <div class="d-flex flex-fill flex-column">
                  <CRow class="d-flex justify-content-center align-items-end">
                    <CCol lg="5" class="c-bg-success c-py-3">
                      <p class="mb-0">Tipo prioridad</p>
                      <v-select :options="tipoPrioridades" label="name" :reduce="data => data.id" v-model="prioridad.type"></v-select>
                    </CCol>
                    <CCol lg="7" class="c-bg-success c-py-3">
                      <p class="mb-0">Descripción</p>
                      <CInput
                        class="d-flex flex-fill mx-1 my-0"
                        placeholder="Descripción"
                        v-model='prioridad.description'
                      >
                        <template #prepend-content><font-awesome-icon icon="list-ol" /></template>
                      </CInput>
                    </CCol>
                    <CCol lg="5" class="c-bg-success c-py-3 mt-1">
                      <p class="mb-0">Fecha inicio</p>
                      <CInput
                        type="date"
                        placeholder="Fecha inicio"
                        v-model='prioridad.start'
                      >
                        <template #prepend-content><font-awesome-icon icon="info-circle" /></template>
                      </CInput>
                    </CCol>
                    <CCol lg="7" class="c-bg-success c-py-3 mt-1">
                      <p class="mb-0">Fecha fin</p>
                      <CInput
                        type="date"
                        placeholder="Fecha fin"
                        v-model='prioridad.end'
                      >
                        <template #prepend-content><font-awesome-icon icon="info-circle" /></template>
                      </CInput>
                    </CCol>
                  </CRow>
                </div>
                <CButton
                  color="primary"
                  size="sm"
                  class="mx-1 my-0"
                  @click="userUpdate.prioridades.splice(index)"
                >
                  <font-awesome-icon icon="trash-alt" />
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    	<template #footer>
	      <CButton
	        color="primary"
	        size="sm"
	        class="m-1"
	        @click="closeModal"
	      >
	        Cancelar
	      </CButton>
	      <CButton
	        color="success"
	        class="m-1"
	        @click="initUpdateUser()"
	      >
	        Actualizar
	      </CButton>
    	</template>
    </CModal>
  </div>
</template>

<script>
import { updateUser } from '../../api/users'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { validate, clean, format } from 'rut.js'

export default {
  name: 'EditUser',
  props: ['showModal', 'user', 'closeModal', 'cuadrantes'],
  components: {'v-select': vSelect},
  data: () => {
    return {
      userData: null,
      fieldsGroups: [
        { key: 'name', label: 'Usuario' },
        { key: 'email', label: 'Email' },
      ],
      showAlertEmail: false,
      tipoPrioridades: [
        {id: 1, name: 'Violencia Intrafamiliar'},
        {id: 2, name: 'Otro'}
      ],
    	userUpdate: {
    		id: '',
    		name: '',
    		email: '',
    		direccion: '',
    		telefono: '',
    	},
      toasts: [],
      clusters: [
        {id: 1, name: 'Mujeres'},
        {id: 2, name: 'Comercio'},
        {id: 3, name: 'Hogar'}
      ]
    }
  },
  watch:{
  	user: function(){
  		if(this.user){
        this.userData = this.user
		  	this.userUpdate = {
		  		id: this.user.id,
		  		name: this.user.name,
		  		email: this.user.email,
          rut: format(this.user.info.rut),
		  		direccion: this.user.info.direccion,
		  		telefono: this.user.info.telefono,
          cuadrante_id: this.user.info.cuadrante_id,
          cluster: this.user.info.cluster,
          prioridades: this.user.prioridad
		  	}
        this.userUpdate.prioridades.map(function(item){
          item.start = new Date(item.start).toISOString().slice(0, 10)
          item.end = new Date(item.end).toISOString().slice(0, 10)
        })
        this.showAlertEmail = false
		  }
      else{
        this.userData = {
          name: 'Nuevo Usuario',
          info: {}
        }
        this.userUpdate = {
          id: null,
          name: '',
          email: '',
          rut: '',
          type: 1,
          direccion: '',
          telefono: '',
          cuadrante_id: this.$store.state.userData.info.cuadrante_id
        }
      }
  	}
  },
  methods: {
    changedRut(){
      console.log(validate(this.userUpdate.rut))
    },
    changedEmail(){
      if(this.user.email !== this.userUpdate.email){
        this.showAlertEmail = true
      }
      else{
        this.showAlertEmail = false
      }
    },
    addPrioridadesArray(){
      this.userUpdate.prioridades.push({
        id: null,
        user_id: this.user.id,
        type: 1,
        description: '',
        start: new Date().toISOString().slice(0, 10),
        end: new Date().toISOString().slice(0, 10)
      })
    },
    initUpdateUser(){
      const self = this
      this.userUpdate.rut = format(this.userUpdate.rut)
      if( !validate(this.userUpdate.rut) ){
        this.toasts.push({
          title: 'Rut incorrecto',
          message: 'El rut ingresado no es válido, por favor verifícalo antes de continuar.'
        })
        return false
      }
      this.userUpdate.rut = clean(this.userUpdate.rut)
      updateUser(this.userUpdate)
        .then(function(resp){
          console.log(resp)
      	  self.closeModal()
        })
        .catch(function(error){
          console.log(error)
          if(error.code === 400){
            self.toasts.push({
              title: 'Error',
              message: 'El rut ingresado ya existe para otro usuario en la base de datos, por favor verifique.'
            })
          }
        })
    }
  }
}
</script>